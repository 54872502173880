import api from 'utils/api'
import contentful from 'utils/contentful'

export const getAll = async () => {
  const events = await contentful.getEntries({
    content_type: 'oooNewsletter',
  })

  return events.items.map((item) => {
    return {
      ...item.fields,
    }
  })
}

export const getClients = async () => {
  const response = await api.get('/newsletter')
  return response.data
}

export interface newsletterDataProps {
  title: string
  body: string
  // eslint-disable-next-line
  files: unknown
}

//eslint-disable-next-line
export const send = async (formData: FormData) => {
  console.log(formData.get('files'))
  await api.post('/newsletter/send', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export default {
  getAll,
  getClients,
  send,
}
