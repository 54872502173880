import { useEffect, useState } from 'react'
import api from 'utils/api'

export default function useNewsletterTemplate() {
  //eslint-disable-next-line
  const [data, setData] = useState<any>(null)
  const [loading, setLoading] = useState(true)

  const fetchData = async () => {
    const response = await api.get('/newsletter/gettemplate')
    setData(response.data)

    setLoading(false)
  }

  useEffect(() => {
    fetchData()
  }, [])

  return { loading, data }
}
