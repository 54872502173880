import React, {
  ChangeEventHandler,
  FormEventHandler,
  useEffect,
  useState,
} from 'react'
import { TextField } from '@mui/material'
import Button, { ButtonStyles } from 'components/ui/button/button'
import './newsletter.Module.scss'
import newsletterService, {
  newsletterDataProps,
} from 'services/newsletterService'
import { toast } from 'react-toastify'
import useNewsletterTemplate from 'hooks/useNewsletterTemplate'
import api from 'utils/api'

export default function AdminNewsletter() {
  const [loggedIn, setLoggedIn] = useState(false)
  const [editing, setEditing] = useState(false)
  const [password, setPassword] = useState('')
  //eslint-disable-next-line
  const [data, setData] = useState<newsletterDataProps | any>({
    title: '',
    body: '',
    files: [],
  })

  const { data: template, loading: loadingTemplate } = useNewsletterTemplate()
  const [newTemplate, setNewTemplate] = useState({ title: '', body: '' })

  useEffect(() => {
    setData({ ...template, files: [] })
    setNewTemplate({ ...template })
  }, [loadingTemplate])

  const onChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (
    e,
  ) => {
    const { name, value } = e.target

    //eslint-disable-next-line
    setData((old: any) => {
      return { ...old, [name]: value }
    })
  }

  const onChangeTemplate: ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (e) => {
    const { name, value } = e.target

    //eslint-disable-next-line
    setNewTemplate((old: any) => {
      return { ...old, [name]: value }
    })
  }

  const onEditSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault()
    try {
      const response = await api.post('/newsletter/edit', {
        ...newTemplate,
        password: '3jhk1348y123h',
      })

      setData(response.data)
      setNewTemplate(response.data)
      setEditing(false)
    } catch (err) {
      console.log(err)
      toast.error('Some error occured')
    }
  }

  const onSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault()

    try {
      const body = new FormData()
      body.append('title', data.title)
      body.append('body', data.body)

      // eslint-disable-next-line
      // data.files.forEach((file: any) => {
      //   console.log(file)
      // })

      // eslint-disable-next-line
      // body.append('files', data.files as any)
      for (let i = 0; i < data?.files?.length; i++) {
        // eslint-disable-next-line
        const file = data.files[i] as any
        body.append('files', file)
      }

      // eslint-disable-next-line
      const response = await newsletterService.send(body)
      console.log(response)

      toast.success('Newsletter send successfully!')
    } catch (err) {
      console.log(err)
      toast.error('Some error occured')
    }
  }

  return !loggedIn ? (
    <div className="adminNewsletter">
      <div className="adminNewsletter__login">
        <div className="login__form">
          <label className="login__title">Login into admin</label>
          <TextField
            label="Parola"
            value={password}
            type="password"
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button
            onClick={() => {
              if (password == 'Catalinwebsiteguru') {
                setLoggedIn(true)
              } else {
                setPassword('')
                toast.error('Password incorrect')
              }
            }}
          >
            Login
          </Button>
        </div>
      </div>
    </div>
  ) : editing ? (
    <div className="adminNewsletter">
      <div className="adminNewsletter__container">
        <div className="container__edit">
          <form className="edit__form" onSubmit={onEditSubmit}>
            <TextField
              label="Titlu"
              variant="filled"
              value={newTemplate.title}
              onChange={onChangeTemplate}
              name="title"
            />
            <TextField
              multiline
              label="Body"
              minRows={10}
              variant="filled"
              value={newTemplate.body}
              onChange={onChangeTemplate}
              name="body"
            />
            <div className="edit__footer">
              <Button type="submit">Edit</Button>
              <Button
                type="button"
                buttonStyle={ButtonStyles.Tertiary}
                onClick={() => setEditing(false)}
              >
                Back
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  ) : (
    <div className="adminNewsletter">
      <div className="adminNewsletter__container">
        <form className="container__form" onSubmit={onSubmit}>
          <label className="form__title">
            Send a Mail to Newsletter Subscribers
          </label>
          <div className="form__body">
            <TextField
              label="Titlu"
              variant="filled"
              value={data.title}
              onChange={onChange}
              name="title"
            />
            <TextField
              multiline
              label="Body"
              minRows={10}
              variant="filled"
              value={data.body}
              onChange={onChange}
              name="body"
            />
          </div>
          <div className="file__input">
            <label className="input__label">Files</label>
            <input
              type="file"
              multiple
              onChange={(e) =>
                //eslint-disable-next-line
                setData((old: any) => {
                  if (e.target.files == null) return

                  return {
                    ...old,
                    files: e.target.files,
                  }
                })
              }
            />
          </div>
          <div className="form__footer">
            <Button>Send Newsletter</Button>
            <Button
              type="button"
              buttonStyle={ButtonStyles.Tertiary}
              onClick={() => setEditing(true)}
            >
              {' '}
              Edit Template
            </Button>
          </div>
        </form>
      </div>
    </div>
  )
}
